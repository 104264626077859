import { calculerCompteRebour, calculerDecompte } from '@/functions/global';
import prisma from '../../lib/prisma';
import { DateTime } from 'luxon';
import { Prisma } from '@prisma/client';
import { getCommunes, getCommunesByDepartement } from './geoApiGouvService';

export async function getVentesPrisma(
    params: URLSearchParams
) {
    const page = parseInt(params.get('page') || '1');
    const limit = parseInt(params.get('itemsPerPage') || '12');
    const statut = params.get('statut') || undefined;
    const tgi = Number(params.get('tgi')) || undefined;
    const typesBiens = params.getAll('typesBiens').map((id) => Number(id)) || [];
    const prixMax = Number(params.get('prixMax')) || undefined;
    const id = Number(params.get('numero')) || undefined;
    const departements = params.getAll('departement');
    const ville = params.get('ville') || undefined;

    const excludeId = params.get('excludeId') ? parseInt(params.get('excludeId')!) : undefined;
    const dateVente = params.get('dateVente') ? DateTime.fromISO(params.get('dateVente')!) : undefined;
    const startDateVente = params.get('startDateVente') ? new Date(params.get('startDateVente')!) : undefined;
    const endDateVente = params.get('endDateVente') ? new Date(params.get('endDateVente')!) : undefined;
    const dateDebut = dateVente?.startOf('day').toJSDate();
    const dateFin = dateVente?.plus({ days: 1 }).startOf('day').toJSDate();
    const offset = (page - 1) * limit;
    const orderBy: { [x: string]: string; }[] = [];

    // Itérer sur tous les paramètres pour trouver ceux qui commencent par 'order['
    params.forEach((value, key) => {
        if (key.startsWith('order[')) {
            const field = key.match(/order\[(.*?)\]/)?.[1]; // Extraire le nom du champ
            if (field) {
                orderBy.push({ [field]: value.toLowerCase() }); // Ajouter au tableau orderBy
            }
        }
        
    });

    let matchingVilleNames: string[] = [];

    if (ville) {
        
        // Normaliser le paramètre ville
        const normalizedVille = ville?.trim()
            .toUpperCase()
            .replace(/\s+/g, ' ')
            .replace(/-/g, ' ')
            .replace(/\bST\b/g, 'SAINT');
    
        // Récupérer les villes distinctes de la base de données
        const distinctVilles = await prisma.vente.findMany({
            select: {
                ville: true,
            },
            distinct: ['ville'],
            where: {
                departement: {
                    in: departements,
                },
            },
            orderBy: {
                ville: 'asc',
            },
        });

        // Normaliser les noms des villes récupérées
        const normalizedVilles = distinctVilles.map(v => ({
            original: v.ville,
            normalized: v.ville.trim()
                .toUpperCase()
                .replace(/\s+/g, ' ')
                .replace(/-/g, ' ')
                .replace(/\bST\b/g, 'SAINT')
        }));

        // Chercher les villes qui correspondent au paramètre ville normalisé
        const matchingVilles = normalizedVilles.filter(v => v.normalized.includes(normalizedVille));

        // Extraire les noms des villes correspondantes
        matchingVilleNames = matchingVilles.map(v => v.original);
    }

    

    const where: any = {
        id: id,
        statut: statut,
        actif: true,
        departement: departements && departements.length ? { in: departements } : undefined,
        ville: matchingVilleNames.length ? { in: matchingVilleNames } : undefined,
        dateVente: {
            gte: dateVente ? dateDebut : startDateVente,
            lt: dateVente ? dateFin : endDateVente,
        },
        prix: {
            lte: prixMax,
            gt: prixMax ? 0 : undefined,
        },
        tgiId: tgi,
        NOT: {
            id: excludeId,
        },
    }

    if(statut === 'resultat') 
    {
        where["OR"] = 
        [
            {
                OR : [
                    { 
                        NOT : { prixAdjudication: null }
                    }   
                    ,{ 
                        NOT : { resultatAdjudication: null }
                    }
                ]
            }
            ,{
                lots : 
                {
                    some : {
                        OR : [
                            {
                                NOT : { adjudication: null }
                            }
                            ,{ 
                                NOT : { resultatAdjudication: null }
                            }
                        ]
                    }
                }
            }
        ];
    }

    // Ajouter conditionnellement la condition de filtrage pour typesBiens
    if (typesBiens && typesBiens.length > 0) {
        where.typesBiens = {
            some: {
                type: {
                    id: { in: typesBiens },
                },
            },
        };
    }

    try {

        // Compter le nombre total de ventes correspondantes
        const totalVentes = await prisma.vente.count({
            where: where
        });

        // Construire la requête avec les paramètres extraits
        const ventes = await prisma.vente.findMany({
            where: where,
            orderBy,
            skip: offset,
            take: limit,
            include: {
                photos: {
                    orderBy: {
                        name: 'asc', 
                    },
                },
                lots: true,
                avocatAdjudication: true,
                tgi: true,
            },
        });

        // Ajouter le champ calculé `compteRebour` à chaque vente
        const ventesAvecCompteRebour = ventes.map(vente => {
            const compteRebour = vente.dateVente ? calculerCompteRebour(vente.dateVente) : null;
            return {
                ...vente,
                compteRebour,
            };
        });

        return {
            ventes: ventesAvecCompteRebour,
            totalVentes,
        };
    } catch (error) {
        console.error("Erreur lors de la récupération de la vente:", error);
        throw error;
    }
}

export type VentePrismaGet = Prisma.VenteGetPayload<{
    include: {
        photos: {
            orderBy: {
                name: 'asc',
            }
        },
        lots: true,
        avocatAdjudication: true,
        tgi: true,
        avocat: true,
        mandataire: true,
        typesBiens: {
            include: {
                type: true, // inclue les détails de `VentesType` associés
            },
        },
        visites: true,
        documents: {
            select: {
                id: true,
                type: true,
            }
        },
        journal: true,
    } & {
        compteRebour?: number;
        decompte?: string;
    }
}>;

export async function getVentePrisma(slug: string, preview=false) {

    try {

        let vente = null; 
        
        if (preview) {
            
            vente = await prisma.vente.findUnique({
                where: {
                    id: parseInt(slug.split('-')[0]),
                    ...(preview ? {} : { actif: true }),
                },
                include: {
                    photos: {
                        orderBy: {
                            name: 'asc',
                        }
                    },
                    lots: true,
                    avocatAdjudication: true,
                    tgi: true,
                    avocat: true,
                    mandataire: true,
                    typesBiens: {
                        include: {
                            type: true,
                        },
                    },
                    visites: true,
                    documents: {
                        select: {
                            id: true,
                            type: true,
                        }
                    },
                    journal: true,
                },
            });
        }else {
        
            const venteByUrl = await prisma.vente.findFirst({
                where: {
                    url: slug,
                    // actif: true,
                },
                include: {
                    photos: {
                        orderBy: {
                            name: 'asc',
                        }
                    },
                    lots: true,
                    avocatAdjudication: true,
                    tgi: true,
                    avocat: true,
                    mandataire: true,
                    typesBiens: {
                        include: {
                            type: true,
                        },
                    },
                    visites: true,
                    documents: {
                        select: {
                            id: true,
                            type: true,
                        }
                    },
                    journal: true,
                },  
            });
            const compteRebour = venteByUrl?.dateVente ? calculerCompteRebour(venteByUrl.dateVente) : null;
            return {
                ...venteByUrl,
                compteRebour,
            };
        }
        const compteRebour = vente?.dateVente ? calculerCompteRebour(vente.dateVente) : null;
        const decompte = vente?.dateVente ? calculerDecompte(vente.dateVente) : null;
        return {
            ...vente,
            compteRebour,
            decompte,
        };
        
    } catch (error) {
        console.error("Erreur lors de la récupération de la vente:", error);
        throw error;
    }
}

export async function getAllAvailableDatesVentesPrisma() {
    const today = new Date();
    try {
        // Utiliser groupBy pour regrouper par dateVente
        const availableDates = await prisma.vente.groupBy({
            by: ['dateVente'],
            where: {
                statut: 'en_cours',
                actif: true,
                dateVente: {
                    gte: today,
                },
            },
            orderBy: {
                dateVente: 'asc',
            },
            _count: {
                dateVente: true,
            },
        });

        // Transformer les résultats pour obtenir uniquement les dates
        const dates: Date[] = availableDates
            .map(entry => entry.dateVente)
            .filter((date): date is Date => date !== null);

        return dates;
    } catch (error) {
        console.error("Erreur lors de la récupération des dates disponibles:", error);
        throw error;
    }
}

export async function getAvailableDateVenteResulatsPrisma() {
    try {
        const availableDates = await prisma.vente.groupBy({
            by: ['dateVente'],
            where: {
                statut: 'resultat',
                actif: true,
            },
            orderBy: {
                dateVente: 'asc',
            },
            _count: {
                dateVente: true,
            },
        });

        // Filtrer pour exclure les valeurs null et transformer les résultats pour obtenir uniquement les dates
        const dates = availableDates
            .map(entry => entry.dateVente)
            .filter((date): date is Date => date !== null);

        return dates;
    } catch (error) {
        console.error("Erreur lors de la récupération des dates disponibles pour les résultats:", error);
        throw error;
    }
}

export async function getAllArchiveDatesVentesPrisma() {
    try {
        const archiveDates = await prisma.vente.groupBy({
            by: ['dateVente'],
            where: {
                statut: 'archive',
                dateVente: {
                    not: new Date("1970-01-01"),
                },
                actif: true,
            },
            orderBy: {
                dateVente: 'asc',
            },
            _count: {
                dateVente: true,
            },
        });

        // Filtrer pour exclure les valeurs null et transformer les résultats pour obtenir uniquement les dates
        const dates = archiveDates
            .map(entry => entry.dateVente)
            .filter((date): date is Date => date !== null);

        return dates;
    } catch (error) {
        console.error("Erreur lors de la récupération des dates d'archive pour les ventes:", error);
        throw error;
    }
}

export async function getBiensEnVenteCountPrisma(): Promise<number> {
    try {
        const count = await prisma.vente.count({
            where: {
                actif: true,
                statut: "en_cours",
            },
        });

        return count;
    } catch (error) {
        console.error("Erreur lors de la récupération du nombre de biens en vente:", error);
        throw error;
    }
}

export async function getBarometre(params: URLSearchParams) {

    const threeYearsAgo = new Date();
    threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);

    const year = params.get('year') ? Number(params.get('year')) : undefined;
    const departements = params.getAll('departement');
    const typesBiens = params.getAll('typesBiens').map((id) => Number(id)) || [];
    

    let dateDebut, dateFin;
    if (year) {
        dateDebut = new Date(year, 0, 1);
        dateFin = new Date(year, 11, 31);
    }

    // données pour tous les types de biens
    const ventes = await prisma.vente.findMany({
        include: {
            lots: true,
        },
        where: {
            OR: [
                { statut: 'resultat' },
                { statut: 'archive' },
            ],
            AND: [
                {
                    OR: [
                        { prixAdjudication: { not: null } },
                        {
                            lots: {
                                some: {
                                    adjudication: { not: null }
                                }
                            }
                        }
                    ]
                },
                {
                    dateVente: year ? {
                        gte: dateDebut,
                        lte: dateFin,
                    } : {
                        gte: threeYearsAgo,
                    }
                },
                {
                    departement: departements && departements.length ? { in: departements } : undefined
                },
                {
                    typesBiens: typesBiens.length > 0 ? {
                        some: {
                            type: {
                                id: { in: typesBiens },
                            },
                        },
                    } : undefined
                }
            ]
        },
        orderBy: {
            dateVente: 'desc',
        },
    });

    const ventesAvecPrix = ventes.length > 0 ? extractPrix(ventes) : [] ;

    const prixMin = ventesAvecPrix.length > 0 ? Math.min(...ventesAvecPrix) : undefined;
    const prixMax = ventesAvecPrix.length > 0 ? Math.max(...ventesAvecPrix) : undefined;
    const prixMoyen = ventesAvecPrix.length > 0 ? Math.round(ventesAvecPrix.reduce((acc, prix) => acc + prix, 0) / ventesAvecPrix.length) : undefined;

    //Données par type de bien
    // Type 'maison'
    const ventesMaison = await prisma.vente.findMany({
        include: {
            lots: true,
        },
        where: {
            OR: [
                { statut: 'resultat' },
                { statut: 'archive' },
            ],
            AND: [
                {
                    OR: [
                        { prixAdjudication: { not: null } },
                        {
                            lots: {
                                some: {
                                    adjudication: { not: null }
                                }
                            }
                        }
                    ]
                },
                {
                    dateVente: year ? {
                        gte: dateDebut,
                        lte: dateFin,
                    } : {
                        gte: threeYearsAgo,
                    }
                },
                {
                    departement: departements && departements.length ? { in: departements } : undefined
                },
                {
                    typesBiens: {
                        some: {
                            type: {
                                label: 'Maison',
                            },
                        },
                    }
                }
            ]
        },
        orderBy: {
            dateVente: 'desc',
        },
    });

    const ventesAvecPrixMaisons = extractPrix(ventesMaison)

    const prixMoyenMaison = ventesAvecPrixMaisons.length > 0 ? Math.round(ventesAvecPrixMaisons.reduce((acc, prix) => acc + prix, 0) / ventesAvecPrixMaisons.length) : undefined;

    // type 'appartement'

    const ventesAppartement = await prisma.vente.findMany({
        include: {
            lots: true,
        },
        where: {
            OR: [
                { statut: 'resultat' },
                { statut: 'archive' },
            ],
            AND: [
                {
                    OR: [
                        { prixAdjudication: { not: null } },
                        {
                            lots: {
                                some: {
                                    adjudication: { not: null }
                                }
                            }
                        }
                    ]
                },
                {
                    dateVente: year ? {
                        gte: dateDebut,
                        lte: dateFin,
                    } : {
                        gte: threeYearsAgo,
                    }
                },
                {
                    departement: departements && departements.length ? { in: departements } : undefined
                },
                {
                    typesBiens: {
                        some: {
                            type: {
                                label: 'Appartement',
                            },
                        },
                    }
                }
            ]
        },
        orderBy: {
            dateVente: 'asc',
        },
    });

    const ventesAvecPrixAppartements = extractPrix(ventesAppartement)

    const prixMoyenAppartement = ventesAvecPrixAppartements.length > 0 ?Math.round(ventesAvecPrixAppartements.reduce((acc, prix) => acc + prix, 0) / ventesAvecPrixAppartements.length) : undefined;

    return prixMin && prixMax && prixMoyen ? {
        prixMin,
        prixMax,
        prixMoyen,
        prixMoyenMaison,
        prixMoyenAppartement,
    }: null;
}

const extractPrix = (ventes: Prisma.VenteGetPayload<{ include: { lots: true } }>[]) => {
    return ventes
        .filter(vente => vente.prixAdjudication !== null || (vente.lots && vente.lots.some(lot => lot.adjudication !== null)))
        .map(vente => {
            if (vente.lots && vente.lots.length > 0) {
                return vente.lots.reduce((acc, lot) => acc + (lot.adjudication || 0), 0);
            } else {
                return vente.prixAdjudication!;
            }
        });
}

export const getDistinctVilles = async (nomVille: string, departements: string[]) => {
    if (!nomVille) {
        return [];
    }
    
    const villes = await getCommunes(nomVille);
    
    const villesFilteredByDepartement: {nom: string, departement: string}[] = villes.filter((ville: any) => departements.includes(ville.departement.code)).map((ville: any) => ({ nom: ville.nom, departement: ville.departement.code }));
    
    const villesOrderedByNom = villesFilteredByDepartement.sort((a: any, b: any) => a.nom.localeCompare(b.nom));
    
    return villesOrderedByNom;
}
